import styled from 'styled-components'
export const Container = styled.section`
  margin-top: 10rem;
  padding: 3rem 15vw;
  padding-top: 0;
  h1 {
    text-align: left;
    font-size: 4.5em;
    color: #b4372a;
    font-weight: 6;
    line-height: 3.6rem;
    font-family: 'GothicJoker', sans-serif;
    span {
      font-weight: 500;
    }
  }
  main {
    a {
      font-family: 'Piazzolla', serif;
      font-weight: 500;
      text-align: justify;
      line-height: 1.6;
      :hover {
        color: #b4372a;
      }
    }
  }
  a {
    font-family: 'Piazzolla', serif;
  }

  /* background: ${({ theme }) => theme.color.bg}; */
`
