import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ open }) => (open ? 'auto' : '40px')};
  transition: width 0.3s ease, left 0.5s ease;
  overflow-x: hidden;
  height: 100%;
  @media (max-width: 768px) {
    width: ${({ open }) => (open ? '100%' : '40px')};
  }
  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .closeButton {
      display: flex;
      align-items: center;
      background: #f2eded;
      width: 100% !important;
      padding: 1.7rem 0.7rem;
      p {
        font-weight: 700;
        color: ${({ theme }) => theme.color.primaryColor};
        font-size: 1.375rem;
      }
    }
  }

  background: ${({ open }) => (open ? '#FBF9F9' : 'transparent')};
  z-index: 4;

  color: ${({ theme }) => theme.color.white};

  nav {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  a {
    color: ${({ theme }) => theme.color.primaryColor};
    font-size: 1.375rem;
    position: relative;
    font-weight: 500;
    z-index: 1;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.color.terciaryColor};
    }

    img {
      max-width: 30px;
    }

    svg {
      path {
        fill: #117614;
      }
    }

    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .active {
    z-index: 1;
    color: ${({ theme }) => theme.color.terciaryColor};

    @keyframes surgeMark {
      to {
        border-radius: 3px;
      }
    }
  }
`

export const HamburgerButton = styled.div`
  display: block;
  position: fixed;
  top: 50%; /* Center vertically */
  left: 2.5rem;
  transform: translateY(-50%); /* Adjust to vertically center the button */
  z-index: 100;
  cursor: pointer;
  text-align: center;

  h2 {
    background: #fff3e5;
    border-radius: 8px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.primaryColor};
    padding: 0.3rem 0.6rem;
  }

  img {
    max-width: 80px;

    &:hover {
      transform: scale(1.1);
      transition: 0.3s;
    }
  }

  .bar {
    width: 25px;
    height: 3px;
    margin: 5px 0;
    background-color: ${({ theme }) => theme.color.primaryColor};
    transition: transform 0.3s;
  }

  &.open {
    display: none;
  }

  @media (max-width: 960px) {
    left: 0;
    img,
    h2 {
      display: none;
    }
    &::before {
      content: '›';
      font-size: 2.5rem;
      color: ${({ theme }) => theme.color.primaryColor};
      position: absolute;
      top: 50%;
      left: -0.1rem;
      transform: translateY(-50%);
      /* padding-bottom: 3rem; */
      border: 2px solid ${({ theme }) => theme.color.primaryColor}; /* Borda ao redor da seta */
      /* border-radius: 50%; Deixa a borda arredondada */
      padding: 0.4rem 0.2rem;
      padding-bottom: 1rem;
      padding-left: 0.5rem;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      background-color: ${({ theme }) =>
        theme.color.white}; /* Fundo branco dentro da borda */
    }
  }
`
