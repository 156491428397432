import styled from 'styled-components'

export const Container = styled.section`
  margin-top: 10rem;
  padding: 3rem 15vw;
  padding-top: 0;

  main {
    a {
      text-align: center;
      font-family: 'Piazzolla', serif;
    }
  }

  p {
    font-size: 1.125rem;
    margin-bottom: 2rem;
    font-family: 'Piazzolla', serif;
    text-align: justify;
  }
  h2 {
    font-family: 'Piazzolla', serif;
  }

  .grid {
    display: grid;
    gap: 2rem;
  }

  .text-center {
    text-align: center;
  }

  @media (max-width: 768px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }

  @media (min-width: 768px) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (min-width: 1024px) {
    .grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`
