import { FooterContainer } from './styles'
import lepeng from './images/lepeng.png'
import ufma from './images/ufma.png'
import ageufma from './images/ageufma.png'
import fapema from './images/fapema.png'
import cnpq from './images/cnpq.png'
import capes from './images/capes.jpg'
import cch from './images/cch.jpg'
import nucleo_humanidades from './images/nucleo_humanidades.png'
import ppg_geo from './images/ppg_geo.png'
import dataluta from './images/dataluta.png'

export default function Footer () {
  return (
    <FooterContainer>
      <div className='flex flex-col items-center'>
        <h2>REALIZAÇÃO</h2>
        <div className='max-w-[200px] mb-4'>
          <img src={lepeng} alt='lepeng' />
        </div>
      </div>
      <div className='flex flex-col items-center mt-4'>
        <h2>APOIO</h2>
        <div className='flex logos-apoio gap-x-2 sm:gap-x-4 items-center'>
          <img src={ufma} alt='ufma' className='w-[20px] sm:w-[60px]'/>
          <img src={ageufma} alt='ageufma' className='w-[50px] sm:w-[140px] sm:h-[40px]'/>
          <img src={fapema} alt='fapema' className='w-[30px] sm:w-[100px]'/>
          <img src={cnpq} alt='cnpq' className='w-[30px] sm:w-[80px]'/>
          <img src={capes} alt='capes' className='w-[20px] sm:w-[50px]'/>
          <img src={cch} alt='cch' className='w-[50px] sm:w-[100px]'/>
          <img src={nucleo_humanidades} alt='nucleo_humanidades' className='w-[20px] sm:w-[80px]'/>
          <img src={ppg_geo} alt='ppg_geo' className='w-[20px] sm:w-[70px]'/>
          <img src={dataluta} alt='dataluta' className='w-[45px] sm:w-[90px]'/>
        </div>
      </div>
    </FooterContainer>
  )
}
