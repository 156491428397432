import React from 'react'
import { Container } from './styles'
import HeaderPages from '../../../components/HeaderPages/HeaderPages'
import Timeline from '../../../components/Timeline/Timeline'
import { data } from './data'
const LinhasDoTempo = () => {
  return (
    <>
      <HeaderPages content='Linha do tempo' />
      <Container>
        <h1 className='mb-4'>
          Questão Agrária
        </h1>
        <p>
          As dinâmicas e processos que conformam a questão agrária maranhense
          podem ser analisadas a partir de uma perspectiva do espaço-tempo. Com
          o objetivo de alcançar um público maior, a Linha do Tempo nasce com a
          proposta de contar de forma objetiva e didática parte de um Maranhão
          desconhecido. Os marcos geo-históricos selecionados resultam de
          estudos, pesquisas e discussões realizadas por pesquisadores do
          LEPENG. Trata-se de pontos de vista que, embora reconheçam muitos
          outros, mas que partem de um conjunto de intencionalidade ao eleger
          determinados espaços-tempos.
        </p>
        <Timeline data={data} />
      </Container>
    </>
  )
}
export default LinhasDoTempo
