import styled from "styled-components";
export const Container = styled.section`
  margin-top: 3rem;
  padding: 3rem 15vw;
  padding-top: 0;
  h1{
    text-align: left;
    font-size: 4.5em;
    color: #B4372A;
    font-weight: 100;
    line-height: 3.6rem;
    font-family: 'GothicJoker', sans-serif;
    span{
      font-weight: 500;
    }
    
  }
  p{
    font-family: 'Piazzolla', serif;
    font-weight: 500;
    text-align: justify;
    line-height: 1.6;
    font-size: 1.2rem;
   
    }
  /* background: ${({ theme }) => theme.color.bg}; */
`;