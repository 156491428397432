import React, { useEffect, useState } from 'react'
import { Spinner } from '@material-tailwind/react'
import { Container } from './styles'
import HeaderPages from '../../components/HeaderPages/HeaderPages'
import { useParams } from 'react-router-dom'

const Post = () => {
  const { id } = useParams()
  const [post, setPost] = useState(null)

  useEffect(() => {
    const fetchPost = async () => {
      const url = `https://www.bdmaranhaoagrario.shop/api/posts/${id}?populate=*`
      const response = await fetch(url, {
        headers: {
          Authorization:
            `Bearer 4b9915a6c4cf1ed9f8e9176f2bf098ab2985140b8ed3cbb71d4d45aa16ff2e7ba54b7dca3d9831853db6939045eeb3d8886a945557ae8a8eff76d137baf592e7d8ebbfec8237928f3346f42a62175028deaf2e6eef8640fd4a7b7aaad751b836bc59e734ee41ca8598ab29a1979af92a693f156528b220e12e9eef76e06d7673`
        }
      })
      const jsonData = await response.json()
      setPost(jsonData.data)
      console.log(jsonData.data)
    }

    fetchPost()
  }, [id])

  return (
    <>
      <HeaderPages content='Notícias' />
      <Container>
        <main className='mt-[-10rem] pt-[3rem]'>
          {post ? (
            <>
              <h1 className='text-4xl font-bold text-red-800 leading-tight mb-10'>
                {post.attributes.title && post.attributes.title}
              </h1>
              <div className='mb-4 md:mb-0 w-full max-w-screen-md mx-auto relative'>
                <img
                  src={post.attributes.image.data.attributes.url}
                  className='w-full h-auto object-cover'
                  alt='Imagem do Post'
                />
                <p className='text-sm text-gray-500 text-right mr-4'>
                  {post.attributes.photo_credit &&
                    `Foto por: ${post.attributes.photo_credit}`}
                </p>
              </div>

              <div className='p-4'>
                <h3 className='text-1xl font-semibold text-red-800 leading-tight mb-8'>
                  {post.attributes.description && post.attributes.description}
                </h3>

                <p className='text-lg text-gray-700'>
                  {post.attributes.text && post.attributes.text.split('\n').map((paragraph, index) => (
                    <span key={index}>
                      {paragraph}
                      <br />
                    </span>
                  ))}
                </p>
                <p className='text-sm text-gray-600 '>
                  Publicado em:{' '}
                  {new Date(post.attributes.createdAt).toLocaleDateString()}
                </p>
                <p className='text-sm text-gray-600'>
                  {post.attributes.author && `Autor: ${post.attributes.author}`}
                </p>
                <p className='text-sm text-gray-600'>
                  {post.attributes.source && `Fonte: ${post.attributes.source}`}
                </p>
              </div>
            </>
          ) : (
            <div className='flex items-end justify-center'>
              <Spinner className='h-10 w-10' />
            </div>
          )}
        </main>
      </Container>
    </>
  )
}
export default Post
