import React from 'react'
import { BoxLeft, BoxRight, BoxContent, Container } from './styles'
import HeaderPages from '../../components/HeaderPages/HeaderPages'
import Carousel from '../../components/Carousel/Carousel'

const Sobre = () => {
  return (
    <>
      <HeaderPages content='Apresentação' />

      <Container>
        <BoxContent>
          <BoxLeft>
            <p>
              O Observatório Maranhão Agrário é um espaço que reúne e
              disponibiliza dados e informações sobre a questão agrária do
              estado do Maranhão. Lançado em 2024 e em processo contínuo de
              desenvolvimento, o Observatório tem por objetivo canalizar
              discussões e meios de pesquisa que permitam a compreensão e
              universalização da diversidade e complexidade do quadro agrário
              mediado por uma leitura do espaço geográfico maranhense.
            </p>
            <p>
              Desenvolvido pelo Laboratório de Extensão, Pesquisa e Ensino de
              Geografia (LEPENG) da Universidade Federal do Maranhão (UFMA), o
              Observatório congrega um <b>portal de notícias</b>, cuja
              finalidade é divulgar informações diversas sobre situações de
              conflitos, resistências, eventos, dentre outros. O site também
              possui um <b>Atlas</b> que reúne mais de 500 mapas do estado do
              Maranhão. Eles contrinuem para leituras e interpretações diversas
              subsidiarias a partir da ciência geográfica.
            </p>
            <p>
              Resultado de um longo processo de pesquisa, a{' '}
              <b>Linha do Tempo</b> da Questão Agrária agrupa um conjunto de
              informações a patir da indissociabilidade do espaçõ e tempo da
              questão agrária. Por sua vez, o <b>Espaço de Memória</b> foi
              pensado como forma de preservar as memórias das vítimas de
              assassinato no campo, - crimes, que alimentados pela impunidade -
              infelimente, ainda são recorrentes no Maranhão.
            </p>
            <p className='lastText'>
              Prof. Dr. Ronaldo Barros Sodré <br /> Coordenador do Observatório
            </p>
          </BoxLeft>
          <BoxRight>
            <Carousel />
          </BoxRight>
        </BoxContent>
      </Container>
    </>
  )
}
export default Sobre
