import React, { useState, useEffect } from 'react'
import { TabBox, Tabs, Tab, Content, ButtonDownload } from './styles'
// import { Cloudinary } from '@cloudinary/url-gen'
import { HiArrowRight } from 'react-icons/hi2'
import { useLocation } from 'react-router-dom'
export default function TabMap2 ({ pageId }) {
  // const cld = new Cloudinary({ cloud: { cloudName: 'dbnsmasqe' } })

  const [content, setContent] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const location = useLocation()
  useEffect(() => {
    setActiveTab(0)
  }, [location])
  useEffect(() => {
    const fetchContent = async () => {
      const numericId = pageId.split('_')[0]
      const response = await fetch(
        `https://bdmaranhaoagrario.shop/api/maps?fields[0]=id&fields[1]=title&fields[2]=level&populate[image][fields][0]=url&populate[parentId][fields][0]=id&filters[parentId][id][$eq]=${numericId}&sort[0]=title&pagination[page]=1&pagination[pageSize]=100`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              `Bearer 4b9915a6c4cf1ed9f8e9176f2bf098ab2985140b8ed3cbb71d4d45aa16ff2e7ba54b7dca3d9831853db6939045eeb3d8886a945557ae8a8eff76d137baf592e7d8ebbfec8237928f3346f42a62175028deaf2e6eef8640fd4a7b7aaad751b836bc59e734ee41ca8598ab29a1979af92a693f156528b220e12e9eef76e06d7673`
          }
        }
      )
      const data = await response.json()

      const formattedContent = data.data.map(item => ({
        id: item.id,
        aba: item.attributes.title,
        imgUrl: item.attributes.image?.data?.attributes?.url || ''
      }))
      setContent(formattedContent)
    }

    fetchContent()
  }, [pageId])

  const handleTabClick = index => {
    setActiveTab(index)
  }

  const formatPageId = pageId => {
    const parts = pageId.split('_')
    parts.shift()
    const capitalizedParts = parts.map(
      part => part.charAt(0).toUpperCase() + part.slice(1)
    )
    return capitalizedParts.join(' ')
  }

  const formattedPageId = formatPageId(pageId)

  const generateImageUrl = imgUrl => {
    return imgUrl // Utilize diretamente a URL fornecida pela API
  }

  return (
    <>
      <TabBox>
        <Tabs>
          <div className='title'>{formattedPageId}</div>
          {content.map((item, index) => (
            <Tab
              key={item.aba}
              onClick={() => handleTabClick(index)}
              isActive={index === activeTab}
            >
              {item.aba}
            </Tab>
          ))}
          {content.length > 0 && (
            <ButtonDownload>
              <a
                href={generateImageUrl(content[activeTab]?.imgUrl || '')}
                target='_blank'
                rel='noreferrer noopener'
                download
                className='flex items-center hover:bg-red-500'
              >
                Veja esta imagem em alta resolução <HiArrowRight size={28} />
              </a>
            </ButtonDownload>
          )}
        </Tabs>
        <Content>
          {content.length > 0 && (
            <img
              src={generateImageUrl(content[activeTab]?.imgUrl || '')}
              alt='mapa'
            />
          )}
        </Content>
      </TabBox>
    </>
  )
}
