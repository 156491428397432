import React, { useState, useEffect, useRef } from 'react';
import { Container, HamburgerButton } from './styles';
import { Squash as Hamburger } from 'hamburger-react';
import button_map_atlas from '../../assets/images/button_map_atlas.png';
import CustomMenu from '../CustomMenu/CustomMenu';

function Menu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const containerRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  // Função que fecha o menu
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Container ref={containerRef} open={menuOpen}>
      <HamburgerButton onClick={toggleMenu} className={menuOpen ? 'open' : ''}>
        <img src={button_map_atlas} alt='browserMap' />
      </HamburgerButton>

      {menuOpen && (
        <div className='navigation'>
          <div className='closeButton'>
            <div>
              <Hamburger
                toggled={menuOpen}
                toggle={setMenuOpen}
                size={20}
                color='#6f3238'
                distance='lg'
              />
            </div>

            <div className='mb-0 mt-4 ml-4'>
              <p>Mapas</p>
            </div>
          </div>
          <div className='px-4 pt-2'>
            {/* Passe a função closeMenu para CustomMenu */}
            <CustomMenu onItemClick={closeMenu} />
          </div>
        </div>
      )}
    </Container>
  );
}

export default Menu;
