import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.css'
import bernardo_mancano_fernandes from '../../assets/images/bernardo-mancano-fernandes.jpg'
const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <Slider {...settings}>
      <div className='carousel-item'>
        <img
          src={bernardo_mancano_fernandes}
          alt='Imagem 1'
        />
        <p className='frase'>"O observatório Maranhão Agrário é o mais novo território da luta pela terra no estado"</p>
        <p>Bernardo Mançano Fernandes</p>
        <p>Geógrafo</p>
      </div>
      <div className='carousel-item'>
        <img
          src='https://res.cloudinary.com/dbnsmasqe/image/upload/v1723305448/questao_agraria/1986_exzoqf.png'
          alt='Imagem 2'
        />
        <p>"Frase de impacto sobre o site"</p>
        <p>Bernardo Mançano Fernandes</p>
        <p>Geógrafo</p>
      </div>
      {/* Adicione mais itens conforme necessário */}
    </Slider>
  )
}

function SampleNextArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'absolute',
        top: '40%',
        right: '-30px', // Ajuste a distância da borda direita
        transform: 'translateY(-50%)',
        zIndex: 2
      }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        position: 'absolute',
        top: '40%',
        left: '-30px', // Ajuste a distância da borda esquerda
        transform: 'translateY(-50%)',
        zIndex: 2
      }}
      onClick={onClick}
    />
  )
}

export default Carousel
