import React from 'react'
import { Container } from './styles'
import HeaderPages from '../../components/HeaderPages/HeaderPages'
import TimelineInfografico from '../../components/TimelineInfografico/TimelineInfografico'
import { data } from './data'
const Infograficos = () => {
  return (
    <>
      <HeaderPages content='Infográficos' />
      <Container>
        <p className='text-justify'>
          Informações sobre assentamentos rurais criados, famílias assentadas,
          índice de desmatamento, trabalho escravo, ameaças de morte, tentativas
          de assassinatos e assassinatos, estão disponibilizadas nesse espaço. O
          comparativo com os dados nacionais, permite ao leitor um panorama e
          provoca para uma leitura crítica sobre a questão agrária maranhense.
        </p>

        <TimelineInfografico data={data} />
      </Container>
    </>
  )
}
export default Infograficos
