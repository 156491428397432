import styled from 'styled-components'

export const Container = styled.section`
  margin-top: 4rem;
  padding: 3rem 10vw;
  padding-top: 0;
  //background-color: #F5F5F5;
`

export const News = styled.section`
  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const NewsBox = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */

  border-radius: 10px;
  padding: 0.5rem;
  .news-image {
    img {
      max-width: 100%;
    }
  }
  .news-text {
    p {
      font-size: 1.2em;
      font-weight: 500;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      color: ${({ theme }) => theme.color.primaryColor};
    }
  }
  .news-link {
    a {
      margin-top: 0;
      text-decoration: none;
      font-weight: 500;
      color: ${({ theme }) => theme.color.primaryColor};
      &:hover {
        color: #6788a1;
      }
    }
  }
`
