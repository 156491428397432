import React from 'react'
import { Container } from './styles'
import { useParams } from 'react-router-dom'
// import Title from "../../components/Title/Title";
// import TabMap from "../../components/TabMap/TabMap";
import TabMap2 from '../../components/TabMap2/TabMap2'
import Menu from '../../components/Menu/Menu'
import HeaderPages from '../../components/HeaderPages/HeaderPages'

const Mapas = () => {
  const { id } = useParams()
  return (
    <>
      <Menu />
      <HeaderPages content='Observatório Maranhão Agrário' marginRight='3rem' />
      <Container>
        {/* <TabMap />
        <br/>
        <br/> */}
        <TabMap2 pageId={id} />
      </Container>
    </>
  )
}
export default Mapas
