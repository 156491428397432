import React from 'react'
import { Container } from './styles'
import HeaderPages from '../../components/HeaderPages/HeaderPages'
import { Link } from 'react-router-dom'
import {
  FaBook,
  FaFileAlt,
  FaFileSignature,
  FaNewspaper,
  FaAtlas
} from 'react-icons/fa' // Exemplo de ícones

const Publicacoes = () => {
  return (
    <>
      <HeaderPages content='Publicações' />
      <Container>
        <main className='mt-[-6rem]'>
          <p className='leading-relaxed text-lg px-10 font-semibold'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed etiam,
            si salvum esse velit, in eo potius consuescere quam in deterius
            vivere. Et quidem saepe quaerimus verbum Latinum par Graeco et quod
            idem valeat; Quod quidem iam fit etiam in Academia.
          </p>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 py-10 text-center'>
            <Link
              to='/publicacoes/teses_e_dissertacoes'
              className='font-bold text-xl flex flex-col items-center text-gray-900'
            >
              <FaFileSignature className='mb-2 text-4xl text-[#6d3127]' /> Teses
              e dissertações
            </Link>
            <Link
              to='/publicacoes/monografias'
              className='font-bold text-xl flex flex-col items-center text-gray-900'
            >
              <FaFileAlt className='mb-2 text-4xl text-[#6d3127]' /> Monografias
            </Link>
            <Link
              to='/publicacoes/livros_e_capitulos'
              className='font-bold text-xl flex flex-col items-center text-gray-900'
            >
              <FaBook className='mb-2 text-4xl text-[#6d3127]' /> Livros e
              capítulos de livros
            </Link>
            <Link
              to='/publicacoes/artigos'
              className='font-bold text-xl flex flex-col items-center text-gray-900'
            >
              <FaNewspaper className='mb-2 text-4xl text-[#6d3127]' /> Artigos
            </Link>
          </div>

          <div className='text-center py-10 '>
            <Link
              to='/publicacoes/relatorios'
              className='font-bold text-xl flex flex-col items-center text-gray-900'
            >
              <FaAtlas className='mb-2 text-4xl text-[#6d3127]' />
              Relatórios Dataluta
            </Link>
          </div>

          <div className='text-center mt-16'>
            <h2 className='text-2xl font-bold text-gray-900'>
              Periódicos parceiros do Observatório Maranhão Agrário
            </h2>
          </div>
        </main>
      </Container>
    </>
  )
}
export default Publicacoes
