import styled from 'styled-components'

export const TabBox = styled.div`
  display: flex;
  max-width: 1500px;
  margin-top: 1rem;
  /* background: #F9F3F2; */
`
export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40%;
  /* padding-right: 0.5rem; */
  .title {
    text-align: center;
    background: #953b2c;
    color: #fff;
    font-weight: 500;
    padding: 0.3rem;
    font-size: 1.1rem;
  }
`

export const Tab = styled.button`
  border: none;
  padding: 0.6rem;
  cursor: pointer;
  border-bottom: solid 1px #dddddd;
  text-align: left;
  background: ${props =>
    props.isActive
      ? '#A35447'
      : '#fff'}; // Aplica a cor de fundo se a aba estiver ativa
  color: ${props =>
    props.isActive
      ? '#fff'
      : '#000'}; // Aplica a cor de texto se a aba estiver ativa
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  img {
    width: 90%;
    max-width: 620px;
    max-height: 780px;
    border: 1px solid #000;
  }
  @media(min-width: 900px) and (max-width: 1200px) {
    img {
      max-width: 550px;
      max-height: 700px;
    }
  }
`

export const ButtonDownload = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
  a {
    text-decoration: none;
    background: #953b2c;
    color: #fff;
    padding: 0.5rem 0.8rem;
    border-radius: 6px;
  }
`
