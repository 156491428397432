// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-item {
  text-align: center;
  padding: 10px;
}
.carousel-item .frase {
  line-height: 1.5;
}

.carousel-item img {
  max-width: 90%;
  padding: 0.5rem 0.5rem;
  height: auto;
  margin: 0 auto;
}

.carousel-item p {
  font-size: 0.8em !important;
  font-weight: bold;
  line-height: 0.7;
  font-family: 'Piazzolla', serif;
}

.slick-prev:before,
.slick-next:before {
  color: #6f3228;
  font-size: 30px;
}

.slick-prev,
.slick-next {
  width: 30px;
  height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Carousel/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;;EAEE,cAAc;EACd,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,YAAY;AACd","sourcesContent":[".carousel-item {\n  text-align: center;\n  padding: 10px;\n}\n.carousel-item .frase {\n  line-height: 1.5;\n}\n\n.carousel-item img {\n  max-width: 90%;\n  padding: 0.5rem 0.5rem;\n  height: auto;\n  margin: 0 auto;\n}\n\n.carousel-item p {\n  font-size: 0.8em !important;\n  font-weight: bold;\n  line-height: 0.7;\n  font-family: 'Piazzolla', serif;\n}\n\n.slick-prev:before,\n.slick-next:before {\n  color: #6f3228;\n  font-size: 30px;\n}\n\n.slick-prev,\n.slick-next {\n  width: 30px;\n  height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
