import React, { useEffect, useState } from 'react'
import { Container, BackgroundVideo } from './styles'

// import Title from "../../components/Title/Title";
import Menu from '../../components/Menu/Menu'
// import image from "../../assets/images/mapa.png";
// import image2 from "../../assets/images/mapa2.jpg";
// import { motion } from "framer-motion";
// import Header from '../../components/Header/Header';
import Header2 from '../../components/Header2/index'
import title from '../../assets/images/title.png'
// import video_home from '../../assets/home.mp4';
import BlogSection from '../../components/BlogSection/BlogSection'
import Footer from '../../components/Footer/Footer'

const Home = () => {
  const [videoSrc, setVideoSrc] = useState('')

  useEffect(() => {
    const videoUrl =
      'https://res.cloudinary.com/dbnsmasqe/video/upload/v1717868179/video/home_wlykmt.mp4'
    const cachedVideoUrl = sessionStorage.getItem('videoUrlObservatorio')

    if (cachedVideoUrl) {
      setVideoSrc(cachedVideoUrl)
    } else {
      setVideoSrc(videoUrl)
      sessionStorage.setItem('videoUrlObservatorio', videoUrl)
    }
  }, [])

  return (
    <>
      <Menu />
      <Container>
        {/* <Header /> */}
        <Header2 />
        <BackgroundVideo
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          src={videoSrc}
          type='video/mp4'
          style={{ pointerEvents: 'none' }}
        />
        <div className='flex justify-center items-center mt-[5rem]'>
          <img
            src={title}
            alt='title'
            className='max-w-[90%] md:max-w-[100%] w-[550px] '
          />
        </div>
      </Container>
      <BlogSection />
      <Footer />
    </>
  )
}

export default Home
