import styled from 'styled-components'
export const Container = styled.section`
  margin-top: 3rem;
  padding: 3rem 10vw;
  padding-top: 0;
  /* background: ${({ theme }) => theme.color.bg}; */ ;
`
export const BoxContent = styled.div`
  display: flex;
  column-gap: 3rem;
`

export const BoxLeft = styled.div`
  width: 75%;
  p {
    text-align: justify;
    font-family: 'Piazzolla', serif;
    font-weight: 500;
    line-height: 1.4;
  }
  .lastText {
    text-align: right;
    line-height: 1.8;
  }
`
export const BoxRight = styled.div`
  width: 25%;
  p {
    font-size: 1em;
  }
`
