import { React, useState } from 'react'
import { HeaderContainer, HomeButton, Title } from './styles'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Dialog } from '@headlessui/react'
import {
  FaHome,
  FaBook,
  FaMap,
  FaMemory,
  FaUsers,
  FaBookOpen,
  FaBars
} from 'react-icons/fa'
import { HiMiniXMark } from 'react-icons/hi2'
import logo from '../../assets/icons/logo.png'
function HeaderPages ({ content, marginRight }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <>
      <HeaderContainer>
        <HomeButton>
          <NavLink to='/'>
            <FontAwesomeIcon icon={faHome} color='#fff' />
          </NavLink>
        </HomeButton>
        <Title marginRight={marginRight}>{content}</Title>
        <div>
          <div className='flex'>
            <button
              type='button'
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className='sr-only'>Open main menu</span>
              <FaBars color='#fff' />
            </button>
          </div>

          <Dialog
            as='div'
         
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className='fixed inset-0 z-10' />
            <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white bg-opacity-95 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
              <div className='flex items-center justify-between'>
                <a href='/' className='-m-1.5 p-1.5'>
                  <img
                    className='h-8 w-auto'
                    src={logo}
                    alt='Observatório Maranhão Agrário'
                  />
                </a>
                <button
                  type='button'
                  className='-m-2.5 rounded-md p-2.5 text-gray-700'
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className='sr-only'>Fechar menu</span>

                  <HiMiniXMark className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>
              <div className='mt-6 flow-root'>
                <div className='-my-6 divide-y divide-gray-500/10'>
                  <div className='space-y-6 py-6'>
                    <NavLink
                      to='/sobre'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <FaHome color='#6F3228' className='mr-2' /> Apresentação
                    </NavLink>
                    <NavLink
                      to='/questao-agraria'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <FaBook color='#6F3228' className='mr-2' /> Linha do tempo
                    </NavLink>
                    <NavLink
                      to='/em-memoria'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <FaMap color='#6F3228' className='mr-2' /> Espaço de memórias
                    </NavLink>
                    <NavLink
                      to='/publicacoes'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <FaMemory color='#6F3228' className='mr-2' /> Publicações
                    </NavLink>
                    <NavLink
                      to='/infograficos'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <FaUsers color='#6F3228' className='mr-2' /> Infográficos
                    </NavLink>
                    <NavLink
                      to='/referencias'
                      className='flex items-center font-bold text-[#6F3228]'
                    >
                      <FaBookOpen color='#6F3228' className='mr-2' />{' '}
                      Referências
                    </NavLink>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
      </HeaderContainer>
    </>
  )
}

export default HeaderPages
