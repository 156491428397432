import { Fragment, useState } from 'react'
import { Dialog, Popover, Transition } from '@headlessui/react'
import { NavLink } from 'react-router-dom'
import { HiMiniXMark } from 'react-icons/hi2'
import {
  FaHome,
  FaBook,
  FaMap,
  FaMemory,
  FaUsers,
  FaBookOpen,
  FaBars
} from 'react-icons/fa'

import logo from '../../assets/icons/logo.png'

export default function Header2 () {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className='bg-white bg-opacity-60'>
      <nav
        className='mx-auto flex max-w-7xl items-center justify-between lg:justify-center p-6'
        aria-label='Global'
      >
        <div className='flex lg:flex-1 lg:hidden'>
          <a href='/' className='-m-1.5 p-1.5'>
            <span className='sr-only'>Observatório Maranhão Agrário</span>
            <img className='h-8 w-auto' src={logo} alt='' />
          </a>
        </div>
        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <FaBars color='#6F3228' />
          </button>
        </div>
        <Popover.Group className='hidden lg:flex lg:gap-x-12 justify-center'>
          <Popover className='relative'>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5'>
                <div className='p-4'></div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <NavLink
            to='sobre'
            className='flex items-center font-bold text-[#6F3228] text-xl'
          >
            <FaHome color='#6F3228' className='mr-2' /> Apresentação
          </NavLink>
          <NavLink
            to='questao-agraria'
            className='flex items-center font-bold text-[#6F3228] text-xl'
          >
            <FaBook color='#6F3228' className='mr-2' /> Linha do tempo
          </NavLink>
          <NavLink
            to='em-memoria'
            className='flex items-center font-bold text-[#6F3228] text-xl'
          >
            <FaMap color='#6F3228' className='mr-2' /> Espaço de memórias
          </NavLink>
          <NavLink
            to='publicacoes'
            className='flex items-center font-bold text-[#6F3228] text-xl'
          >
            <FaMemory color='#6F3228' className='mr-2' /> Publicações
          </NavLink>
          <NavLink
            to='infograficos'
            className='flex items-center font-bold text-[#6F3228] text-xl'
          >
            <FaUsers color='#6F3228' className='mr-2' /> Infográficos
          </NavLink>
          <NavLink
            to='referencias'
            className='flex items-center font-bold text-[#6F3228] text-xl pr-4'
          >
            <FaBookOpen color='#6F3228' className='mr-2' /> Referências
          </NavLink>
        </Popover.Group>
      </nav>
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-10' />
        <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white bg-opacity-95 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <a href='/' className='-m-1.5 p-1.5'>
              <img
                className='h-8 w-auto'
                src={logo}
                alt='Observatório Maranhão Agrário'
              />
            </a>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Fechar menu</span>

              <HiMiniXMark className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-6 py-6'>
                <NavLink
                  to='sobre'
                  className='flex items-center font-bold text-[#6F3228]'
                >
                  <FaHome color='#6F3228' className='mr-2' /> Apresentação
                </NavLink>
                <NavLink
                  to='questao-agraria'
                  className='flex items-center font-bold text-[#6F3228]'
                >
                  <FaBook color='#6F3228' className='mr-2' /> Linha do tempo
                </NavLink>
                <NavLink
                  to='em-memoria'
                  className='flex items-center font-bold text-[#6F3228]'
                >
                  <FaMap color='#6F3228' className='mr-2' /> Espaço de memórias
                </NavLink>
                <NavLink
                  to='publicacoes'
                  className='flex items-center font-bold text-[#6F3228]'
                >
                  <FaMemory color='#6F3228' className='mr-2' /> Publicações
                </NavLink>
                <NavLink
                  to='infograficos'
                  className='flex items-center font-bold text-[#6F3228]'
                >
                  <FaUsers color='#6F3228' className='mr-2' /> Infográficos
                </NavLink>
                <NavLink
                  to='referencias'
                  className='flex items-center font-bold text-[#6F3228]'
                >
                  <FaBookOpen color='#6F3228' className='mr-2' /> Referências
                </NavLink>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
