import React from 'react'
import { Container } from './styles'
import HeaderPages from '../../../components/HeaderPages/HeaderPages'
import TimelineMemoria from '../../../components/TimelineMemoria/TimelineMemoria'
import { data } from './data'
const LinhasDoTempo = () => {
  return (
    <>
      <HeaderPages content='Linha do tempo' />
      <Container>
        <h1 className='mb-4'>
          ESPAÇOS DE MEMÓRIAS
        </h1>
        <p className='text-justify'>
          Os registros aqui compilados capturam uma fração importante, porém
          dolorosa, de uma realidade ainda mais complexa e brutal. No cenário
          agrário, o Maranhão se configura como um dos estados mais violentos do
          campo brasileiro, circunstância que nos leva a um contexto, onde
          muitas vidas são interrompidas abruptamente. Nosso propósito é
          apresentar os sujeitos que foram vitimados no campo maranhense, não
          apenas para mensuração quantitativa, mas para honrar suas memórias,
          dando eco à luta que travaram até o fim.
        </p>
        <p className='text-justify'>
          Aqui, prestamos homenagens aos corajosos que enfrentaram, e aos que
          ainda enfrentam as agruras do campo maranhense na busca por uma vida
          mais digna. São mulheres, homens, jovens e crianças, despojados dos
          direitos mais básicos, mas imbuídos de uma determinação inabalável.
          Seus nomes podem não estar gravados em mármore, mas estão entalhados
          nas páginas da história, como mártires e símbolos de uma causa
          nobre e urgente.
        </p>
        <TimelineMemoria data={data} />
      </Container>
    </>
  )
}
export default LinhasDoTempo
