import styled from 'styled-components'

export const FooterContainer = styled.footer`
  max-width: 100%;
  min-height: 200px;
  background-color: #fff;
  color: ${({ theme }) => theme.color.primaryColor};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 1rem;
  h2 {
    font-size: 1.2rem;
    
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  .logos-apoio {
    max-width: 100%;
  }
`
