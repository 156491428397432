import React from "react";
import { Container } from "./styles";
import HeaderPages from "../../components/HeaderPages/HeaderPages";

const Referencias = () => {
  return (
    <>
      <HeaderPages content="Referências" />
      <Container></Container>
    </>
  );
};
export default Referencias;
