import React, { useEffect, useState } from 'react';
import { News, NewsBox } from './styles';
import { ArrowDownward } from '@mui/icons-material';
import { Spinner } from '@material-tailwind/react';

export default function BlogSection() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const url =
        'https://www.bdmaranhaoagrario.shop/api/posts?populate=*&sort[0]=createdAt:desc&pagination[limit]=6';
      const response = await fetch(url, {
        headers: {
          Authorization:
            `Bearer 4b9915a6c4cf1ed9f8e9176f2bf098ab2985140b8ed3cbb71d4d45aa16ff2e7ba54b7dca3d9831853db6939045eeb3d8886a945557ae8a8eff76d137baf592e7d8ebbfec8237928f3346f42a62175028deaf2e6eef8640fd4a7b7aaad751b836bc59e734ee41ca8598ab29a1979af92a693f156528b220e12e9eef76e06d7673`
        }
      });
      const jsonData = await response.json();
      setLoading(false);
      setPosts(jsonData.data);
      sessionStorage.setItem('postsObservatorio', JSON.stringify(jsonData.data));
    };

    const cachedPosts = sessionStorage.getItem('postsObservatorio');

    if (cachedPosts) {
      setPosts(JSON.parse(cachedPosts));
    } else {
      fetchPosts();
    }
  }, []);

  return (
    <div className='flex flex-col items-center'>
      {!loading ? (
        <News>
          {posts.map((post, index) => (
            <NewsBox key={post.id}>
              <a href={`post/${post.id}`}>
                <div className='news-text'>
                  <p>{post.attributes.title}</p>{' '}
                </div>
              </a>
              <div className='news-image'>
                <a href={`post/${post.id}`}>
                  <img
                    src={post.attributes.image.data.attributes.url}
                    alt='news'
                  />
                </a>
              </div>
            </NewsBox>
          ))}
        </News>
      ) : (
        <div className='flex items-center justify-around bg-[#FFF3E5] w-full py-12'>
          <div>
            <Spinner className='h-10 w-10' color='red' />
          </div>
          <div>
            <Spinner className='h-10 w-10' color='red' />
          </div>
          <div>
            <Spinner className='h-10 w-10' color='red' />
          </div>
        </div>
      )}

      <div className='w-full bg-[#FFF3E5] text-center pb-[2rem] mt-[-2rem]'>
        <a
          href='/noticias'
          className='text-[#6F3228] hover:text-[#6788A1] font-bold text-center text-lg'
        >
          Veja mais
          <ArrowDownward />
        </a>
      </div>
    </div>
  );
}